
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import useTrackEventOnShow from '../../../../hooks/useTrackEventOnShow';
import Button from '../../../../components/Button';
import Image from '../../../../components/Image';
import { trackClickEvent } from '../../../../utils/analytics';
import { analyticsTags } from '../../../../utils';
import s from './Promo.module.scss';
const propTypes = {
    displayTitle: PropTypes.string,
    intro: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
    pageName: PropTypes.string,
    tags: PropTypes.array,
    contentTypeId: PropTypes.string,
    contentfulId: PropTypes.string,
    promoLogo: PropTypes.string,
    promoType: PropTypes.string.isRequired
};
const componentName = 'Promo';
const Promo = ({ displayTitle = '', intro = '', image = '', label = '', url = '', pageName = '', contentTypeId = '', contentfulId = '', tags = [], promoLogo = '', promoType, }) => {
    const { componentRef, verticalIndex } = useVerticalIndex();
    const [ref] = useTrackEventOnShow({
        threshold: 0.3,
        eventName: 'Promo Content Displayed',
        eventOptions: {
            cta_text: label,
            content_name: displayTitle,
            content_type: contentTypeId,
            content_location: promoType,
            content_index: 1,
            content_id: contentfulId,
            content_tags: analyticsTags(tags),
            location: pageName,
            nonInteraction: 1
        }
    });
    const promoLogoSet = [
        {
            src: promoLogo
        },
    ];
    return (<div className={s.promo} data-testid="promoRoot" ref={ref}>
      <div className={s.image} ref={componentRef}>
        <Image imageSet={[
            {
                src: `${image}?w=375&h=550&fit=fill&fm=webp 1x, ${image}?w=750&h=1100&fit=fill&fm=webp 2x, ${image}?w=1125&h=1650&fit=fill&fm=webp 3x`,
                media: '(max-width: 480px)',
                type: 'image/webp'
            },
            {
                src: `${image}?w=375&h=550&fit=fill 1x, ${image}?w=750&h=1100&fit=fill 2x, ${image}?w=1125&h=1650&fit=fill 3x`,
                media: '(max-width: 480px)',
                type: 'image/jpeg'
            },
            {
                src: `${image}?w=750&h=1100&fit=fill&fm=webp 1x, ${image}?w=1550&h=2200&fit=fill&fm=webp 2x`,
                media: '(max-width: 1024px)',
                type: 'image/webp'
            },
            {
                src: `${image}?w=750&h=1100&fit=fill 1x, ${image}?w=1550&h=2200&fit=fill 2x`,
                media: '(max-width: 1024px)',
                type: 'image/jpeg'
            },
            {
                src: `${image}?w=1440&fm=webp 1x, ${image}?w=2880&fm=webp 2x`,
                media: '(max-width: 1440px)',
                type: 'image/webp'
            },
            {
                src: `${image}?w=1440 1x, ${image}?w=2880 2x`,
                media: '(max-width: 1440px)',
                type: 'image/jpeg'
            },
            {
                src: `${image}?w=2560&fm=webp`,
                media: '(max-width: 2560px)',
                type: 'image/webp'
            },
            {
                src: `${image}?w=2560`,
                media: '(max-width: 2560px)',
                type: 'image/jpeg'
            },
            {
                src: `${image}?fm=webp`,
                type: 'image/webp'
            },
            {
                src: `${image}?w=2560`
            },
        ]} cover lazy/>
      </div>
      <div className={s.contentWrapper}>
        <Image className={s.logo} imageSet={promoLogoSet}/>
        <div className={s.displayTitle}>{displayTitle}</div>
        <div className={s.intro}>{intro}</div>
        <div className={s.buttonWrapper}>
          <a href={url}>
            <Button className={s.btn} label={label} level="secondary2" tabIndex="-1" data-testid="promo-cta" onClick={() => {
            trackClickEvent({
                destination_url: url,
                label,
                content_name: displayTitle,
                content_type: contentTypeId,
                content_id: contentfulId,
                content_tags: analyticsTags(tags),
                location: componentName,
                vertical_index: verticalIndex,
                horizontal_index: 0
            });
        }}/>
          </a>
        </div>
      </div>
    </div>);
};
Promo.propTypes = propTypes;
export default Promo;

    async function __Next_Translate__getStaticProps__19229ae0c18__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/Promo/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19229ae0c18__ as getStaticProps }
  